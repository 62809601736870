import * as React from 'react';
import { graphql } from 'gatsby';
import { Box, Text, Flex } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AiFillPlayCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

import SeoComponent from '../components/SeoComponent';
import LinkWrapper from '../components/LinkWrapper';
import { FeaturedContentHome } from '../sections/FeaturedContentHome';
import SectionsBlogPostSelector from '../sections/SectionsBlogPostSelector';

/* IMPORT APP CONTEXT */
import { AppContext } from '../context';

/* MODAL SETUP */
const customStyles = {
  overlay: {
    zIndex: 9999,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  content: {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement('#___gatsby');

function BlogPostTemplate(props) {
  const { pageContext, data } = props;
  
  const { author, isPublic, mainImage, publishedAt, resume, section, seoDescription, seoTitle, slug, title, iframe, subcategory, allSubcategories } = pageContext.blogPost;
  const appContext = React.useContext(AppContext);

  const learnCategories = {
    read: 'read',
    watch: 'watch',
    listen: 'listen', 
  };

  const seoImg = data.blogPost?.mainImage || data.blogPost.seoImage?.image;

  /* VERIFY CATEGORY, SUBCATEGORY AND MEDIA TYPE */
  const [category, setcategory] = React.useState(null);
  const [learnRoute, setLearnRoute] = React.useState(null);
  const postSubcategories = allSubcategories.map(item => item.name);

  React.useEffect(() => {
    let currentCategory = '';

    if (iframe && iframe.startsWith('http')) {
      currentCategory = learnCategories.watch;

    } else if (iframe && !iframe.startsWith('http')) {
      currentCategory = learnCategories.listen;

    } else {
      currentCategory = learnCategories.read;
    }

    setcategory(currentCategory);

    const learnCategory = data.allSanityLearnCategory.nodes;
    setLearnRoute(learnCategory[0].learnRoute.route);

  }, []);

  /* POSTS BY CATEGORY AND SUBCATEGORY */
  const groupedPosts = pageContext.postsCategory === "/null/" ? data.readPosts.group : data.mediaPosts.group;
  const postsByLearnSubcategory = groupedPosts.find(item => item.fieldValue === subcategory.slug.current);

  /* MODAL CONTROL */
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [videoURL, setVideoURL] = React.useState(null);

  const openModal = () => {
    const url = category === learnCategories.watch ? iframe : '';
    setVideoURL(url);
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <>
      <SeoComponent
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImg?.asset?.url}
      />

      <Box
        w="100%"
        h={{base:'auto'}}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        // border="1px"
      >
        {/* GRAY BOX */}
        <Box
          w="100%"
          h="496px"
          bgColor="grayBG"
          position="absolute"
          top="0"
          style={{zIndex:0}}
        >
          {/* SVG BOTTOM IMAGE */}
          <SvgCurve top={false} />
        </Box>

        {/* MAIN IMAGE AND TITLE */}
        <Flex
          maxW="857px"
          direction="column"
          mt={{base:'70px', md:'70px'}}
          style={{zIndex:2}}
          justifyContent="center"
          alignItems="center"
          mb={{base:'7.29vw', md:'7.29vw', xl:'105px'}}
          // bgColor="blue"
        >
          {/* BACK TO WANA CATEGORY */}
          {learnRoute && (
            <LinkWrapper href={learnRoute || '/'}>
              <Text
                w="max-content"
                color='primary'
                fontSize={{base:'16px', md:'16px'}}
                fontWeight="800"
                lineHeight={{base:'20px', md:'20px'}}
                letterSpacing={{base:'4.8px', md:'4.8px'}}
                textAlign="center"
                textTransform="uppercase"
                borderBottom="1px"
                borderColor="transparent"
                _hover={{borderBottom:'1px', borderColor:'#272158'}}
                mb={{base:'15px', md:'15px'}}
              >
                {'< wana learn'}
              </Text>
            </LinkWrapper>
          )}

          {/* TITLE */}
          <Text
            maxW={{base:'93vw', mds:'857px'}}
            color='primary'
            fontSize={{base:'28px', ms:'32px', msx:'36px', md:'40px', mds:'44px', lg:'48px', lgs:'50px'}}
            fontWeight={{base:'900', md:'900'}}
            lineHeight={{base:'34px', ms:'39px', msx:'44px', md:'49px', mds:'54px', lg:'58px', lgm:'62px', lgs:'66px', xl:'70px'}}
            letterSpacing={{base:'-0.22px', md:'-0.39px'}}
            // textTransform="uppercase"
            textAlign="center"
            mb={{base:'42px', md:'46px'}}
            // border="1px"
          >
            {title}
          </Text>

          {/* MAIN IMAGE / COVER */}
          <Flex
            maxW={{base:'90vw', ssm:'330px', ms:'354px', msx:'54.1vw', md:'54vw', lg:'54vw', xl:'778px'}}
            h={{base:'211px', ms:'225px', msx:'34.38vw', md:'34.38vw', lg:'34.38vw', xl:'495px'}}
            position="relative"
            // border="1px"
          >
            {mainImage && (
              <GatsbyImage image={mainImage.asset.gatsbyImageData} alt={'wana post cover image'} />
            )}

            {/* PLAY BUTTON IF MEDIA IS A VIDEO */}
            {category === 'watch' && (
              <Box
                w="100%"
                h="100%"
                position="absolute"
                top="0"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {/* ICON */}
                <Box
                  w="auto"
                  h="auto"
                  borderRadius="full"
                  cursor='pointer'
                  style={{zIndex:2}}
                  onClick={openModal}
                >
                  <AiFillPlayCircle style={{ fontSize: '92px', color: '#272158' }} />
                </Box>

                {/* ICON WHITE BACKGROUND */}
                <Box
                  w="46px"
                  h="46px"
                  bgColor="white"
                  borderRadius="full"
                  position="absolute"
                />
              </Box>
            )}
          </Flex>

          {/* AUDIO IFRAME */}
          {category === 'listen' && (
            <Box
              w={{base:'330px', ms:'354px', msx:'54.1vw', md:'54vw', lg:'54vw', xl:'778px'}}
              h="auto"
              display="flex"
              justifyContent="center"
              alignItems="flex-end"
              mt="10px"
            >
              {/* PODCST IFRAME */}
              <Flex
                className="audio-iframe"
                w="100%"
                maxH="auto"
                border="1px"
                borderColor="gray.400"
                // style={{boxShadow:'0px 5px 10px -10px #131029'}}
                dangerouslySetInnerHTML={{
                  __html: `${iframe}`,
                }}
              />
            </Box>
          )}
        </Flex>

        {/* BLOG SECTIONS */}
        {section && (
          <Flex
            direction="column"
            maxW={{base:'87%', ms:'87%', msx:'87%', md:'87%', lg:'84%', xl:'1200px'}}
            mx="auto"
            zIndex="1"
            alignItems="center"
          >
            {section.map((blogSection, indx) => (
              <SectionsBlogPostSelector
                key={`one-section-${blogSection?._key}-${indx}`}
                data={blogSection}
              />
            ))}
          </Flex>
        )}

        {/* CONTENT */}
        {category && (
          <FeaturedContentHome
            mt={{base:'20px', md:'20px'}}
            wanaKnowMore
            postSubcategories={postSubcategories}
            currentPostTitle={title}
            postsByLearnSubcategory={postsByLearnSubcategory}
          />
        )}
      </Box>

      {/* MODAL */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel='watch modal'>
        <Box className='player-wrapper'>
          <ReactPlayer className='react-player' width='100%' height='100%' playing={modalIsOpen} auto url={videoURL} controls={true}/>
        </Box>
      </Modal>
    </>
  );
}

const SvgCurve = ({ top }) => {
  const position = top ? {top:'0'} : {bottom:'0'};

  return (
    <Box
      w="100%"
      h={top ? {base:'2%', ms:'3%', md:'5%', mds:'10%', lg:'15%'} : {base:'30%', md:'25%', lg:'30%', xl:'30%', xxl:'31%', sl:'32%', ssl:'40%'}}
      position="absolute"
      left="0"
      style={{zIndex:0, ...position}}
    >
      {/* TOP SVG */}
      {top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            // d="M0.28,122.88 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
            d="M-0.27,140.63 C250.27,141.63 310.10,-85.34 500.27,106.09 L500.00,0.00 L0.00,0.00 Z"
          >
          </path>
        </svg>
      )}

      {/* BOTTOM SVG */}
      {!top && (
        <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
          <path
            fill="white"
            d="M-0.27,73.53 C192.15,3.48 325.90,143.59 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          >
          </path>
        </svg>
      )}
    </Box>
  );
};

export const query = graphql`
  query BLOG_POST_QUERY ($id: String!) {

    allSanityLearnCategory {
      nodes {
        slug {
          current
        }
        postsRoute {
          route
        }
        learnRoute {
          route
        }
      }
    }
    readPosts: allSanityBlogPost(
      filter: {iframe: {eq: null}, isPublic: {eq: true}}
      sort: {fields: _updatedAt, order: DESC}
      ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        subcategory {
          name
          slug {
            current
          }
        }
        allSubcategories {
          name
          slug {
            current
          }
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        resume
      }
      group(field: subcategory___slug___current) {
        fieldValue
        nodes {
          isPublic
          title
          iframe
          slug {
            current
          }
          subcategory {
            name
            slug {
              current
            }
          }
          allSubcategories {
            name
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
            }
          }
          resume
        }
      }
    }
    mediaPosts: allSanityBlogPost(
      filter: {iframe: {regex: "/^http/"}, isPublic: {eq: true}}
      sort: {fields: _updatedAt, order: DESC}
      ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        subcategory {
          name
          slug {
            current
          }
        }
        allSubcategories {
          name
          slug {
            current
          }
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        resume
      }
      group(field: subcategory___slug___current) {
        fieldValue
        nodes {
          isPublic
          title
          iframe
          slug {
            current
          }
          subcategory {
            name
            slug {
              current
            }
          }
          allSubcategories {
            name
            slug {
              current
            }
          }
          mainImage {
            asset {
              gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
            }
          }
          resume
        }
      }
    }
    blogPost: sanityBlogPost(id: {eq: $id }) {
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      mainImage {
        asset {
          url
        }
      }
    }

  }
`;

export default BlogPostTemplate;

